.unauthorized {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  .error-code {
    font-family: 'Nunito ExtraBold';
    font-size: 45px;
  }

  .label {
    font-family: 'Nunito ExtraBold';
    font-size: 20px;
  }
}