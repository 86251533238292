.header {
  background: linear-gradient(#02154c, #426b9d);
  padding: 20px;

  @media only screen and (min-width: 800px) {
    margin-top: -50px;
  }
}

.header-tags {
  position: fixed;
  top: 0;
  max-width: 500px;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 5px 8px rgb(0 0 0 / 5%), 0 5px 8px rgb(0 0 0 / 5%);
  transform: translateY(-100%);
  transition: all .3s ease;
  overflow-x: auto;
  visibility: hidden;
  z-index: 1000;

  &.show {
    transform: translateY(0);
    visibility: visible;
  }

  .tags {
    display: inline-flex;
    background-color: #fff;
    padding: 13px 15px;
    box-sizing: border-box;
  }

  .tag {
    margin-left: 5px;
    background-color: #eee;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 3px 6px;
    font-family: 'Nunito ExtraBold';
    font-size: 12px;
    text-align: center;
    min-width: 60px;
    white-space: nowrap;
    color: #333;
    cursor: pointer;
  }
}

.category,
.flex {
  position: relative;

  .top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .see-all {
      font-family: 'Nunito Bold';
      font-size: 14px;
      cursor: pointer;
    }

    .title {
      font-family: 'Nunito Bold';
      font-size: 18px;
    }
  }

  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 10px;
  }

  .content-product {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: .5rem;
  }
}

.merchant-container {
  background-color: #fff;
  padding: 1px 0 20px 0;

  .see-more {
    font-family: 'Nunito Bold';
    font-size: 15px;
    text-align: center;
  }
}

.new-voucher {
  position: relative;
  background: linear-gradient(#cfd8ed, #f3f4fa);
  padding: 20px 0;

  .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 33.3%;
    height: 100%;
    background-image: url('../../@assets/icons/ic_new_voucher.png');
    background-repeat: no-repeat;
    background-size: 50%;
    background-position: center;
  }

  .slider {
    position: relative;
    z-index: 2;
  }
}

.slick-slide {
  padding-right: 10px;
  box-sizing: border-box;
}