.redeem-failed {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  height: calc(100vh - 50px);

  .failed-label {
    font-family: 'Nunito ExtraBold';
    font-size: 25px;
    margin-top: 10px;
    margin-bottom: 90px;
  }

  .button {
    position: absolute;
    bottom: 20px;
    width: calc(100% - 40px);
    margin: 0 auto;
    background-color: #375AA8;
    border: 1px solid #375AA8;
    font-family: 'Nunito Bold';
    font-size: 15px;
    color: #fff;
    line-height: 40px;
    padding: 0 20px;
    border-radius: 20px;
    cursor: pointer;
  }
}