.topbar {
  position: fixed;
  top: 0;
  max-width: 500px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  height: 50px;
  background-color: white;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.05), 0 5px 8px rgba(0, 0, 0, 0.05);
  z-index: 999;

  @media only screen and (max-width: 800px) {
    display: none;
  }

  .back {
    flex-shrink: 0;
    cursor: pointer;
  }

  .title {
    flex: 1;
    text-align: center;
    font-family: 'Nunito Bold';
    line-height: 50px;
    text-transform: capitalize;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}