.detail-product {
  position: relative;

  .image {
    background-color: #fff;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 90%;
    padding-top: 100%;
  }

  .preview {
    padding: 10px;
    background-color: #fff;

    img {
      width: 100%;
    }

  }

  .detail {
    background-color: #fff;
    padding: 20px;
    padding-bottom: 150px;
    border-top: 1px solid #ddd;

    .tags {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 20px;

      .tag {
        margin-left: 5px;
        background-color: #eee;
        border: 1px solid #ddd;
        border-radius: 5px;
        padding: 3px 6px;
        font-family: 'Nunito ExtraBold';
        font-size: 10px;
        color: #333;
        cursor: pointer;
      }
    }

    .select-amount {
      margin: 10px 0;
      font-family: 'Nunito Bold';
      font-size: 15px;
    }

    .amount {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: .5rem;

      .voucher {
        position: relative;
        border: 1px solid #eee;
        box-shadow: 0 5px 8px rgba(0, 0, 0, 0.05), 0 5px 8px rgba(0, 0, 0, 0.05);
        border-radius: 10px;
        background-color: white;
        overflow: hidden;
        padding: 15px;
        min-height: 100px;
        box-sizing: border-box;
        transition: all .3s ease;
        cursor: pointer;

        &.active {
          border: 3px solid #375AA8;
        }

        .check {
          position: absolute;
          top: 5px;
          right: 5px;
          transition: all .3s ease;
          transform: scale(0);

          &.show {
            transform: scale(1);
          }
        }

        .name {
          font-family: 'Nunito ExtraBold';
          font-size: 12px;
          margin: 10px 0;
        }

        .point {
          font-family: 'Nunito Bold';
          font-size: 12px;
          margin-top: 5px;
          color: #375aa8;
        }
      }
    }
  }
}

.bottom-action {
  position: fixed;
  bottom: 0;
  max-width: 500px;
  width: 100%;

  .insufficient {
    position: absolute;
    top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F9B9BB;
    border-bottom: 1px solid #ddd;
    padding: 10px;
    box-sizing: border-box;
    font-family: 'Nunito Bold';
    font-size: 11px;
    text-align: center;
    visibility: hidden;
    transition: all .3s ease;
    z-index: 1;

    &.show {
      top: -40px;
      visibility: visible;
    }
  }

  .content {
    position: relative;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    background-color: #fff;
    border-top: 1px solid #ddd;
    z-index: 2;
  }

  .point {
    font-family: 'Nunito Bold';
    font-size: 12px;

    .product-point {
      font-size: 15px;
      color: #375aa8;
      margin-bottom: 5px;
    }

    .my-point {
      font-family: 'Nunito Bold';
      font-size: 12px;

      span {
        font-family: 'Nunito ExtraBold';
        transition: all .3s ease;
      }
    }
  }

  .button {
    background-color: #375AA8;
    border: 1px solid #375AA8;
    font-family: 'Nunito Bold';
    font-size: 15px;
    color: #fff;
    line-height: 40px;
    padding: 0 20px;
    border-radius: 20px;
    transition: all .3s ease;
    cursor: pointer;

    &:disabled {
      background-color: #ccc;
      border-color: #ccc;
      cursor: default;
    }
  }
}

.overflow {
  position: fixed;
  top: 0;
  max-width: 500px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  z-index: 900;
  transition: all .3s ease;
  opacity: 0;
  visibility: hidden;

  &.open {
    opacity: 1;
    visibility: visible;
  }
}

.modal {
  position: fixed;
  bottom: 0;
  max-width: 500px;
  width: 100%;
  box-sizing: border-box;
  background-color: #fff;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  z-index: 1000;
  transform: translateY(100%);
  transition: all .3s ease;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.05), 0 5px 8px rgba(0, 0, 0, 0.05);

  &.open {
    transform: translateY(0);
  }

  .close-button {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
  }

  .form {
    padding: 20px;

    .title {
      font-family: 'Nunito Bold';
      font-size: 15px;
      margin-bottom: 20px;
    }

    .input {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 10px;
      border: 1px solid #ddd;
      border-radius: 20px;
      overflow: hidden;

      input {
        border: none;
        width: 100%;
        font-family: 'Nunito Bold';
        font-size: 15px;
        line-height: 40px;
        -webkit-user-select: text
      }

      .icon {
        height: 25px;
      }
    }

    .name {
      font-family: 'Nunito Bold';
      font-size: 15px;
      margin-top: 20px;
    }

    .number-storage {
      display: flex;
      overflow: auto;
      margin-top: 10px;

      .number {
        font-family: 'Nunito Bold';
        font-size: 15px;
        line-height: 30px;
        padding: 0 10px;
        border: 1px solid #375AA8;
        color: #375AA8;
        border-radius: 20px;
        margin-right: 5px;

        &.disabled {
          opacity: .5;
          border-color: #777;
          color: #777;
        }

        &:last-child {
          margin: 0;
        }
      }
    }

    .validate {
      font-family: 'Nunito Bold';
      font-size: 15px;
      color: #b1161c;
      margin-top: 5px;
    }
  }

  .bottom-action {
    position: relative;
  }

  .otp-box {
    padding: 1rem;
    text-align: center;

    .button {
      width: 100%;
      margin-top: 2rem;
      background-color: #375AA8;
      border: 1px solid #375AA8;
      font-family: 'Nunito Bold';
      font-size: 15px;
      color: #fff;
      line-height: 40px;
      padding: 0 20px;
      border-radius: 20px;
      transition: all .3s ease;
      cursor: pointer;

      &:disabled {
        background-color: #ccc;
        border-color: #ccc;
        cursor: default;
      }
    }

    .title {
      font-family: 'Nunito Bold';
      font-size: 15px;
      margin: 1rem 0;
    }

    .info {
      font-family: 'Nunito Regular';
      font-size: 12px;
      margin: 1rem 0;
    }

    .resend {
      font-family: 'Nunito Regular';
      font-size: 12px;
      margin: .5rem 0;
    }

    .otp-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .otp-input {
      width: 25% !important;
      max-width: 50px;
      margin: 4px;
      border-radius: .5rem;
      border: 1px solid #ddd;
      padding: 5px;
      font-size: 1.5rem;
    }
  }
}