.voucher-tags {
  overflow-x: auto;

  .tags {
    display: inline-flex;
    padding: 20px 15px 0 15px;
    box-sizing: border-box;
  }

  .tag {
    margin-left: 5px;
    background-color: #eee;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 3px 6px;
    font-family: 'Nunito ExtraBold';
    font-size: 12px;
    text-align: center;
    min-width: 60px;
    white-space: nowrap;
    color: #333;
    cursor: pointer;

    &.active {
      background-color: #375aa8;
      color: #fff;
    }
  }
}

.product {
  position: relative;
  padding: 20px;
  box-sizing: border-box;

  .content {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: .5rem;
  }
}