.profile {
  position: relative;
  padding: 20px;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.05), 0 5px 8px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  background-color: white;
  height: 80px;
  margin-top: 20px;
  overflow: hidden;

  .title {
    font-family: 'Nunito Bold';
    font-size: 14px;
  }

  .name {
    font-family: 'Nunito Bold';
    font-size: 18px;
    margin-top: 10px;
    text-transform: capitalize;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: calc(100% - 100px);
  }

  .point {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    padding: 10px;

    .value {
      font-size: 25px;
      font-family: 'Nunito ExtraBold';
      color: #375AA8;
    }

    .label {
      text-align: center;
    }
  }

  .image {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 50px;
    height: 50px;
    background-image: url('../../@assets/images/bg_warning.png');
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: contain;
  }

  &.skeleton {

    .title,
    .name,
    .value,
    .label {
      background: #eee;
      color: #eee;
      width: 100px;
      border-radius: 20px;
      animation: skeleton 1s ease infinite;
    }

    .title {
      width: 130px;
    }

    .name {
      width: 100px;
    }

    .value,
    .label {
      width: 100px;
      margin-bottom: 5px;
    }
  }
}