.category-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 22%;
  margin: 1.5%;
  padding: 7px 5px;
  box-sizing: border-box;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.05), 0 5px 8px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  background-color: white;
  margin-bottom: 10px;
  cursor: pointer;

  .icon {
    width: 50%;
    height: auto;
  }

  .name {
    font-family: 'Nunito Bold';
    font-size: 10px;
    text-align: center;
    margin-top: 5px;
    text-transform: capitalize;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
  }

  &.skeleton {

    .name,
    .icon-skeleton {
      background: #eee;
      color: #eee;
      width: 100px;
      border-radius: 20px;
      animation: skeleton 1s ease infinite;
    }

    .name {
      height: 10px;
      width: 45px;
    }

    .icon-skeleton {
      width: 30px;
      height: 30px;
    }
  }
}