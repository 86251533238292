@import '@styles/fonts';

html {
  padding: 0;
  margin: 0;
  height: -webkit-fill-available;
}

body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ddd;
  height: -webkit-fill-available;
}

#root {
  height: -webkit-fill-available;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  user-select: none;
  -webkit-tap-highlight-color: transparent;

  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.error {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
  width: 100%;
  max-width: 500px;
  background-color: #b1161c;
  font-family: 'Nunito Bold';
  font-size: 15px;
  color: #fff;
  padding: 10px;
  box-sizing: border-box;
  text-align: center;
  z-index: 10000;
}

input {
  outline: none;
}

@keyframes skeleton {
  50% {
    opacity: 0;
    transform: scaleY(.8);
  }
}