.merchant {
  position: relative;
  border: 1px solid #eee;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.05), 0 5px 8px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  background-color: white;
  overflow: hidden;
  cursor: pointer;

  .image {
    width: 100%;
    padding-top: 100%;
    background-color: #fff;
    background-position: center;
    background-size: 90% auto;
    background-repeat: no-repeat;
    border-bottom: 1px solid #eee;
  }

  .detail {
    padding: 10px;

    .name {
      font-family: 'Nunito Bold';
      font-size: 12px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      word-break: break-word;
      overflow: hidden;
    }

    .start-from {
      font-family: 'Nunito Regular';
      font-size: 12px;
      margin-top: 6px;
      color: #a4a4a4;
    }

    .nominal {
      font-family: 'Nunito Bold';
      font-size: 12px;
      margin-top: 2px;
    }

    .point {
      font-family: 'Nunito Bold';
      font-size: 12px;
      color: #375aa8;
      margin-top: 2px;
    }
  }

  &.skeleton {

    .image {
      background: #f9f9f9;
    }

    .name,
    .start-from,
    .nominal,
    .point {
      background: #eee;
      color: #eee;
      border-radius: 20px;
      animation: skeleton 1s ease infinite;
    }

    .name {
      width: 90%;
    }

    .start-from {
      display: inline-block;
    }

    .nominal {
      width: 70%;
    }

    .point {
      width: 50%;
    }
  }
}