@font-face {
  font-family: 'Nunito Regular';
  src: url('../@fonts/Nunito-Regular.ttf');
}

@font-face {
  font-family: 'Nunito Light';
  src: url('../@fonts/Nunito-Light.ttf');
}

@font-face {
  font-family: 'Nunito SemiBold';
  src: url('../@fonts/Nunito-SemiBold.ttf');
}

@font-face {
  font-family: 'Nunito Bold';
  src: url('../@fonts/Nunito-Bold.ttf');
}

@font-face {
  font-family: 'Nunito Extrabold';
  src: url('../@fonts/Nunito-ExtraBold.ttf');
}