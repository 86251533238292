.container {
  position: relative;
  max-width: 500px;
  width: 100%;
  min-height: -webkit-fill-available;
  background-color: #f5f5f5;
  margin: 0 auto;

  @media only screen and (min-width: 800px) {
    padding-top: 50px;
  }
}